@font-face {
  font-family: 'Exo';
  font-weight: 900;
  src: local('Exo'), url(./assets/fonts/Exo-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 900;
  src: local('Exo'), url(./assets/fonts/Exo-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 700;
  src: local('Exo'), url(./assets/fonts/Exo-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 700;
  font-style: italic;
  src: local('Exo'), url(./assets/fonts/Exo-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 800;
  src: local('Exo'), url(./assets/fonts/Exo-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 800;
  font-style: italic;
  src: local('Exo'), url(./assets/fonts/Exo-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 200;
  src: local('Exo'), url(./assets/fonts/Exo-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 200;
  font-style: italic;
  src: local('Exo'), url(./assets/fonts/Exo-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 300;
  src: local('Exo'), url(./assets/fonts/Exo-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 300;
  font-style: italic;
  src: local('Exo'), url(./assets/fonts/Exo-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 400;
  src: local('Exo'), url(./assets/fonts/Exo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 600;
  src: local('Exo'), url(./assets/fonts/Exo-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo';
  font-weight: 600;
  font-style: italic;
  src: local('Exo'), url(./assets/fonts/Exo-SemiBoldItalic.ttf) format('truetype');
}

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 900;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-Black.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 900;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-BlackItalic.ttf) format('truetype');
} */

@font-face {
  font-family: 'SegoeUI';
  font-weight: 700;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SegoeUI';
  font-weight: 700;
  font-style: italic;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-BoldItalic.ttf) format('truetype');
}

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 800;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-ExtraBold.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 800;
  font-style: italic;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-ExtraBoldItalic.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 200;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-ExtraLight.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 200;
  font-style: italic;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-ExtraLightItalic.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 300;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-Light.ttf) format('truetype');
} */

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 300;
  font-style: italic;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-LightItalic.ttf) format('truetype');
} */

@font-face {
  font-family: 'SegoeUI';
  font-weight: 400;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-Regular.ttf) format('truetype');
}

/* @font-face {
  font-family: 'SegoeUI';
  font-weight: 600;
  src: local('SegoeUI'), url(./assets/fonts/SegoeUI-SemiBold.ttf) format('truetype');
} */
